.card {
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-size: cover;
    background-position: center;
  }
  
  .title {
    font-weight: 300;
    color: var(--mantine-color-white);
    line-height: 1.2;
    font-size: 32px;
    margin-top: var(--mantine-spacing-xs);
    cursor: pointer;
    transition: color 0.3s ease;
  }

  .title.red {
    color: red;
  }
  
  .title.green {
    color: green;
  }

  .hd {
    font-weight: bold;
    color: green;
    text-decoration: none;
  }
  .movie {
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
}
.hub {
  font-weight: bold;
  color: #117029;
  text-decoration: none;
}
b, strong {
  font-weight: 600;
}
#myDIV {
  background: #fe7c3d;
  animation: mymove 15s infinite;
}
@media (max-width: 768px) {
  .alert1 {
      margin: 11px 10px -5px 10px !important;
  }
}


.alert1 {
  padding: 5px;
  margin-top: -8px;
  margin-bottom: 0px !important;
  background-color: #20B2AA;
  color: #ffffff;
  margin: 15px 0px 0px 0px;
  border-radius: 3px;
}
.closebtn {
  margin-left: 15px;
  color: #fff;
  font-weight: 700;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: .3s;
}

.row {
  --bs-gutter-x: 0rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1* var(--bs-gutter-y));
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));
}

  
  .category {
    color: var(--mantine-color-white);
    opacity: 0.7;
    font-weight: 700;
    text-transform: uppercase;
    cursor: default;
  }


  @keyframes blink {
    0% { color: red; }
    33% { color: rgb(0, 249, 104); }
    66% { color: white; }
    100% { color: red; }
  }
  
  .blinking-text {
    animation: blink 3s infinite;
  }



  .custom-card {
    margin-bottom: 20px; 
    width:18%;
    height:"400px";
  }

  .banner-image{
    height:"100%";
    width:"100%";
    transition: transform 0.3s ease;
  }

  .banner-image:hover {
    transform: scale(0.9); 
  }
  .betclass{
    display: none;
  }

  @media (max-width: 768px) {
    .custom-card {
      width: 47%; 
      height:"450px";
    }

    .banner-image{
      height:"300px";
      width:"100%";
    }
    .betclass{
      display: inline;
    }
  }
  


  