.movie_banner{
    width: 300px;
}

.alert .m1 {
    padding: 15px;
    margin: 20px;
    border: 1px solid;
    background-color: #fe7c3d;
    border-radius: 4px;
}
.alert .t1 {
    color: greenyellow;
}

.alert .m2 {
    padding: 15px;
    margin: 20px;
    border: 1px solid;
    color: black;
    background-color: whitesmoke;
    border-radius: 4px;
}
.alert .t2 {
    color: #1a73e8;
}
.alert .m3 {
    padding: 15px;
    margin: 20px;
    border: 1px solid;
    background-color: #117029;
    border-radius: 4px;
}


.trailer-video{
    width: 97%;
    height: 500px;
}


@media (max-width: 768px) {
    .movie_banner{
        width: 400px;
    }
    .alert {
        margin: 15px 10px 0px 10px;
    }
    .trailer-video{
        width: 97%;
        height: 300px;
    }
}

